<template>
  <p-dialog
    button="提交"
    :visible.sync="confirmVisible"
    width="480px"
    @confirm="handleConfirm"
    @cancel="handleCancel"
  >
    <span slot="title">{{ title }}</span>
    <template slot="content">
      <div class="content">
        <el-row>
          <el-col>
            <label>当前所属税期：</label>
            <span class="taxPeriod">{{ fmtTaxPeriod(taxPeriod) }}</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <label>本次勾选：</label>
            <span>{{ selections.length }}份</span>
          </el-col>
          <el-col :span="12">
            <label>合计金额：</label>
            <span>{{ sumAmount }}元</span>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <label>税额合计：</label>
            <span>{{ sumTaxAmount }}元</span>
          </el-col>
          <el-col :span="12">
            <label>有效税额合计：</label>
            <span>{{ sumValidTaxAmount }}元</span>
          </el-col>
        </el-row>
      </div>
    </template>
  </p-dialog>
</template>

<script>
import PDialog from "@/components/pdt/PDialog";
import { submitInvoicesToCheck } from "@/service/deduction";

export default {
  name: "ConfirmDeductionDialog",
  components: { PDialog },
  props: ["visible", "isChecked", "taxNo", "taxPeriod", "selections", "orgId"],
  computed: {
    title() {
      return this.isChecked ? "是否提交抵扣" : "是否取消抵扣勾选";
    },
    // 合计金额
    sumAmount() {
      let amount = this.selections.reduce((c, r) => c + r.amount, 0);
      return amount.toFixed(2);
    },
    // 合计税额
    sumTaxAmount() {
      let taxAmount = this.selections.reduce((c, r) => c + r.taxAmount, 0);
      return taxAmount.toFixed(2);
    },
    // 合计有效税额
    sumValidTaxAmount() {
      let validAmount = this.selections.reduce(
        (c, r) => c + Number(r.deductTaxAmount),
        0
      );
      return validAmount.toFixed(2);
    },
  },
  data() {
    return {
      invoiceList: [],
      confirmVisible: false,
    };
  },
  watch: {
    visible() {
      this.confirmVisible = this.visible;
    },
  },
  methods: {
    // 提交
    async handleConfirm() {
      this.buildInvoiceList();
      const param = {
        taxNo: this.taxNo,
        isCheck: this.isChecked,
        orgId: this.orgId,
        invoices: this.invoiceList,
      };
      const { success } = await submitInvoicesToCheck(param);
      if (success) {
        this.$emit("success");
      } else {
        this.$emit("failed");
      }
      this.invoiceList = [];
      this.$emit("update:visible", false);
    },
    // 构建发票代码
    buildInvoiceList() {
      this.selections.forEach((i) => {
        let invoice = {
          id: i.id,
          taxAmountValid: i.deductTaxAmount,
        };
        this.invoiceList.push(invoice);
      });
    },
    // 取消
    handleCancel() {
      this.$emit("update:visible", false);
      this.$emit("failed");
    },
    // 格式化方法多次触发，这里可能有重绘问题
    fmtTaxPeriod(val) {
      if (val) {
        let arr = val.split("");
        arr.splice(4, 0, "-");
        return arr.join("");
      } else {
        return "--";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  font-size: 14px;
  padding: 24px 0;

  .el-row {
    padding-bottom: 14px;

    label {
      color: #666666;
    }

    span {
      color: #333333;
    }

    .taxPeriod {
      color: #f5222d;
    }
  }
}
</style>
